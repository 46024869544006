import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TippyCloseOnClickOutsideDirective } from '../../../../../../src/app/modules/directives/tippy-close-on-click-outside.directive';
import { TippyComponentInstance } from '../../../../../../src/app/components/shared/enum';
import { RoutePathResolverService } from '../../../../../../src/app/modules/services/route-path-resolver.service';
import { NgxTippyService } from 'ngx-tippy-wrapper';
import { GsConnectService } from '../../stand-alone/gs-connect/services/gs-connect.service';
import { GsConnectNotificationItem, GsConnectPostItem } from '../../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { GSConnectGroupNotification, GsConnectPostTypeEnum, PostNotificationType } from '../../stand-alone/gs-connect/models/gs-connect.model';
import { PostDatePipe } from '../../../../../../../goldstar-share/src/app/components/shared/post-date/post-date.pipe';
import { GsConnectPostNotificationMessagePipe } from '../../../../../../../goldstar-share/src/app/components/shared/gs-connect-post-notification-message/gs-connect-post-notification-message.pipe';
import { Subject, takeUntil } from 'rxjs';

@Component({
	standalone: true,
	imports: [CommonModule, RouterModule, TippyCloseOnClickOutsideDirective, PostDatePipe, GsConnectPostNotificationMessagePipe],
	selector: 'app-gs-connect-notification',
	templateUrl: './gs-connect-notification.component.html',
	styleUrls: ['./gs-connect-notification.component.scss'],
})
export class GsConnectNotificationComponent implements OnInit {
	notificationGroupList: GSConnectGroupNotification[] = [];
	postNotificationType: typeof PostNotificationType = PostNotificationType;
	gsConnectPostType: typeof GsConnectPostTypeEnum = GsConnectPostTypeEnum;
	gsConnectPostTypeList: string[] = [];
	showOpenGoldstarButton: boolean = true;
	private ngUnSubscribe$ = new Subject<void>();
	constructor(
		private elementRef: ElementRef,
		private tippyService: NgxTippyService,
		private router: Router,
		private routePathResolverService: RoutePathResolverService,
		public gsConnectService: GsConnectService
	) {}
	groupNotifications(items: GsConnectNotificationItem[]): GSConnectGroupNotification[] {
		const grouped: { [key: string]: GSConnectGroupNotification } = {};

		items.forEach((item) => {
			if (item.notificationType && item.gsConnectPostTypeSystemCode && item.gsConnectPostGUID) {
				const key =
					item.notificationType == PostNotificationType.COMMENT
						? `${item.notificationType}_${item.gsConnectPostTypeSystemCode}_${item.parentGsConnectPostGUID}`
						: `${item.notificationType}_${item.gsConnectPostTypeSystemCode}_${item.gsConnectPostGUID}`;
				if (!grouped[key]) {
					grouped[key] = {
						notificationType: item.notificationType,
						gsConnectPostTypeSystemCode: item.gsConnectPostTypeSystemCode,
						gsConnectPostGUID: item.gsConnectPostGUID,
						postByUserNames: [],
						postDateTime: item.postDateTime,
						parentPostTypeSystemCode: item.parentPostTypeSystemCode,
						parentGsConnectPostGUID: item.parentGsConnectPostGUID,
					};
				}
				if (item.postByUserName) {
					grouped[key].postByUserNames.push(item.postByUserName);
				}
			}
		});
		const filteredData: GSConnectGroupNotification[] = Object.values(
			Object.values(grouped).reduce<Record<string, GSConnectGroupNotification>>((acc, item) => {
				const key = `${item.gsConnectPostGUID}-${item.postByUserNames.join(',')}`;
				if (!acc[key] || (item.notificationType === 'TAG' && acc[key].notificationType === 'POST')) {
					acc[key] = item;
				}
				return acc;
			}, {})
		);
		return filteredData;
	}

	ngOnInit() {
		this.gsConnectPostTypeList = Object.values(this.gsConnectPostType).filter((t) => t != GsConnectPostTypeEnum.EVENT);

		let gsConnectComponentGUID = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('GsConnectComponent');
		if (gsConnectComponentGUID && this.router.url.includes(gsConnectComponentGUID ?? '')) {
			this.showOpenGoldstarButton = false;
		} else {
			this.showOpenGoldstarButton = true;
		}
		this.gsConnectService.postNotificationList.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((notificationList: GsConnectNotificationItem[]) => {
			this.notificationGroupList = this.groupNotifications(notificationList);
		});
	}
	ngOnDestroy(): void {
		this.ngUnSubscribe$.next();
		this.ngUnSubscribe$.unsubscribe();
	}

	async openGsConnectPage(item?: GSConnectGroupNotification) {
		let gsConnectComponentGUID = this.routePathResolverService.resolveRouterUrlGUIDFromComponentName('GsConnectComponent');
		let gsConnectPostGUID: string = '';
		if (item) {
			if (
				item.gsConnectPostTypeSystemCode == GsConnectPostTypeEnum.COMMENT &&
				(item.notificationType == this.postNotificationType.REACTION || item.notificationType == this.postNotificationType.COMMENT || item.notificationType == this.postNotificationType.TAG)
			) {
				let parentPost = await this.gsConnectService.fetchParentPost(item.gsConnectPostGUID ?? '');
				if (parentPost.isSuccess) {
					gsConnectPostGUID = parentPost.data?.gsConnectPostGUID ?? '';
				}
			} else if (item.notificationType == this.postNotificationType.COMMENT) {
				gsConnectPostGUID = item.parentGsConnectPostGUID ?? '';
			} else {
				gsConnectPostGUID = item.gsConnectPostGUID ?? '';
			}
		}
		if (gsConnectPostGUID) {
			this.gsConnectService.selectedGsConnectPostGUID.next(gsConnectPostGUID);
			this.tippyService.hide(TippyComponentInstance.GsConnect);
			this.router.navigate(['', gsConnectComponentGUID]);
		} else {
			this.gsConnectService.selectedGsConnectPostGUID.next('');

			this.tippyService.hide(TippyComponentInstance.GsConnect);
			this.router.navigate(['', gsConnectComponentGUID]);
		}
	}
	close() {
		this.tippyService.hide(TippyComponentInstance.GsConnect);
		// const userHistory = document.getElementsByClassName('user-history-container')[0];
		// userHistory?.classList?.remove('pos-relative');
		// const favComponent = document.getElementsByClassName('favorite-container')[0];
		// favComponent?.classList?.remove('pos-relative');
	}
}
