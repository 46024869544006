import { Component, OnInit } from '@angular/core';
import { ConfirmationPopupService } from '../../../modules/services/confirmation-popup.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../models/classes';
import { DirtySaveHasUnsavedChangeAction } from '../../../store/store.actions';
import { CommonModule } from '@angular/common';
import { DxPopupModule } from 'devextreme-angular';
import { RouterModule } from '@angular/router';

@Component({
	selector: 'app-confirmation-popup',
	templateUrl: './confirmation-popup.component.html',
	styleUrls: ['./confirmation-popup.component.scss'],
})
export class ConfirmationPopupComponent implements OnInit {
	showResetConfirmationPopup: boolean = false;
	message: string = 'Are you sure you want to discard your changes?';
	title: string = 'Confirmation';
	onConfirmationPopUpConfirmed: () => void = () => {};
	onConfirmationPopUpCancel: () => void = () => {};
	constructor(
		private confirmationPopUpService: ConfirmationPopupService,
		private store: Store<AppState>
	) {}

	ngOnInit() {
		this.confirmationPopUpService.confirmationModal.subscribe((data) => {
			this.showResetConfirmationPopup = true;
			this.title = data.title == '' ? 'Confirmation' : data.title;
			this.message = data.message == '' ? 'Are you sure you want to discard your changes?' : data.message;
			this.onConfirmationPopUpConfirmed = data.onConfirm;
			if (data.onCancel) {
				this.onConfirmationPopUpCancel = data.onCancel;
			}
		});
	}
	onConfirmation() {
		this.showResetConfirmationPopup = false;
		this.store.dispatch(DirtySaveHasUnsavedChangeAction({ hasUnSavedChanges: false }));
		this.onConfirmationPopUpConfirmed();
	}

	hidePopup() {
		this.showResetConfirmationPopup = false;
		this.onConfirmationPopUpCancel();
	}
}
