import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { SideNavbarComponent } from '../side-navbar/side-navbar.component';
import { InstancesChanges, NgxTippyProps, NgxTippyService } from 'ngx-tippy-wrapper';
import { AuthService } from '../../../components/auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DropDownMenuModel } from '../../models/models';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app/models/classes';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { dirtySaveActionState, navbarTitleUpdated, suggestionUpdated, userIdState, userPermissionLoaded, userProfileUpdated } from '../../../../app/store/store.selector';
import { UserInfoService } from '../../../services/user-info.service';
import { GsConnectNotificationItem, InternalTitlePermissionListItem, InternalUserItem } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { MyDayPopupComponent } from './my-day-popup/my-day-popup.component';
import { TippyComponentInstance } from '../../../components/shared/enum';
import { RoutePathResolverService } from '../../services/route-path-resolver.service';
import { PermissionService } from '../../../../../../goldstar-share/src/app/services/permission.service';
import _ from 'lodash';
import { ConfirmationPopupService } from '../../services/confirmation-popup.service';
import { FeedbackPopupComponent } from './feedback-popup/feedback-popup.component';
import { NotificationService } from '../stand-alone/notifications/services/notification.service';
import { ImpersonateUserComponent } from '../../../components/impersonate/impersonate-user/impersonate-user.component';
import { S3FileService } from '../../../../../../goldstar-share/src/app/services/s3file-service';
import { FeedbackService } from '../stand-alone/feedback/services/feedback.service';
import { SuggestionUpdateAction, UserProfileUpdateAction } from '../../../../app/store/store.actions';
import { GsConnectNotificationComponent } from './gs-connect-notification/gs-connect-notification.component';
import { GsConnectService } from '../stand-alone/gs-connect/services/gs-connect.service';
@Component({
	selector: 'app-top-navbar',
	templateUrl: './top-navbar.component.html',
	styleUrls: ['./top-navbar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TopNavbarComponent implements OnInit, OnChanges, OnDestroy {
	public userHasImpersonatePermission!: boolean;
	public tippyProps: NgxTippyProps = {
		trigger: 'click',
		interactive: true,
		zIndex: 9999,
	};
	public readonly favoriteComponent = SideNavbarComponent;
	public readonly myDayPopupComponent = MyDayPopupComponent;
	public readonly feedbackPopupComponent = FeedbackPopupComponent;
	public readonly gsConnectNotificationPopupComponent = GsConnectNotificationComponent;
	containerExpanded: boolean = true;
	togglerIcon: string = 'bx-chevron-left';
	@Output() onTogglerClick: EventEmitter<void> = new EventEmitter();
	@Input() sideBarExpanded!: boolean;
	public loggedInUserName!: string;
	private allUsers: InternalUserItem[] = [];
	public title!: string;
	public profileMenuList: DropDownMenuModel[] = [];
	public navigationMenuList: DropDownMenuModel[] = [];

	public componentGUID!: string | undefined;

	public hasFeedbackPermission!: boolean;

	public baseMenuModels: DropDownMenuModel[] = [
		{
			name: 'Profile',
			icon: 'bx-user',
			onClick: this.onProfileMenuClick.bind(this),
		},
		{
			name: 'Logout',
			icon: 'bx-left-arrow-circle',
			onClick: this.onProfileMenuClick.bind(this),
		},
	];
	hasUnsavedChanges: boolean = false;
	public readonly addFeedbackPermissionRoute: string = '/dashboard/add-question-comment-suggestion';

	isFeedbackInitialized: boolean = false;
	isGsConnectNotificationInitialized: boolean = false;
	isMyDayInitialized: boolean = false;
	isFavoritePopupInitialized: boolean = false;
	defaultProfileImage: string = '../../../../../../assets/images/Trb-latest-svg/trb-silhouette-transparent.png';
	public userProfileImage: string = this.defaultProfileImage;
	private ngUnSubscribe$ = new Subject<void>();
	hasSuggestions: boolean = false;
	hasPostNotification: boolean = false;
	constructor(
		private authService: AuthService,
		public dialog: MatDialog,
		private router: Router,
		private store: Store<AppState>,
		private tippyService: NgxTippyService,
		private userInfoService: UserInfoService,
		private routePathResolver: RoutePathResolverService,
		private permissionService: PermissionService,
		private notificationService: NotificationService,
		private confirmationPopUpService: ConfirmationPopupService,
		private fileService: S3FileService,
		private feedbackService: FeedbackService,
		private gsConnectService: GsConnectService
	) {}

	async ngOnInit(): Promise<void> {
		this.initializeSubscriptions();

		this.store
			.select(dirtySaveActionState)
			.pipe(takeUntil(this.ngUnSubscribe$))
			.subscribe((hasUnSavedChanges) => {
				this.hasUnsavedChanges = hasUnSavedChanges ?? false;
			});
		this.tippyService.instancesChanges.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((changes: InstancesChanges) => {
			if (changes.reason == 'setInstance') {
				switch (changes.name) {
					case TippyComponentInstance.Feedback:
						let feedback = this.tippyService.getInstance(TippyComponentInstance.Feedback);
						if (!feedback?.state.isShown) {
							setTimeout(() => {
								this.tippyService.show(TippyComponentInstance.Feedback);
							}, 10);
						}
						break;
					case TippyComponentInstance.MyDay:
						let myDayInstance = this.tippyService.getInstance(TippyComponentInstance.MyDay);
						if (!myDayInstance?.state.isShown) {
							this.tippyService.show(TippyComponentInstance.MyDay);
						}
						break;
					case TippyComponentInstance.Favourite:
						let favInstance = this.tippyService.getInstance(TippyComponentInstance.Favourite);
						if (!favInstance?.state.isShown && this.isFavoritePopupInitialized) {
							// Here the string is the tippy instance name
							this.tippyService.show(TippyComponentInstance.Favourite);
						}
						break;
					case TippyComponentInstance.GsConnect:
						let gsConnectInstance = this.tippyService.getInstance(TippyComponentInstance.GsConnect);
						if (!gsConnectInstance?.state.isShown && this.isGsConnectNotificationInitialized) {
							// Here the string is the tippy instance name
							this.tippyService.show(TippyComponentInstance.GsConnect);
						}
						break;
					default:
						break;
				}
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.sideBarExpanded) {
			this.expandSideNav();
		}
	}

	initializeSubscriptions() {
		// SUBSCRIPTION FOR NAV BAR TITLE UPDATE EVENT
		this.store
			.select(navbarTitleUpdated)
			.pipe(takeUntil(this.ngUnSubscribe$))
			.subscribe((title: string) => {
				this.title = title;
			});
		this.store
			.select(userProfileUpdated)
			.pipe(takeUntil(this.ngUnSubscribe$))
			.subscribe(async (isUpdated: boolean) => {
				if (isUpdated) {
					let user = this.userInfoService.currentLoggedInUser.value;
					if (user.smallPictureS3FileGUID) {
						let fileToLoad = await this.fileService.downloadS3FilePreSignedUrl(
							{
								s3FileGUID: user.smallPictureS3FileGUID,
								createDownloadLink: false,
							},
							false
						);
						if (fileToLoad?.file) {
							this.userProfileImage = URL.createObjectURL(fileToLoad?.file);
						} else {
							this.userProfileImage = this.defaultProfileImage;
						}
					} else {
						this.userProfileImage = this.defaultProfileImage;
					}
					this.store.dispatch(
						UserProfileUpdateAction({
							userProfileUpdated: false,
						})
					);
				}
			});
		// SUBSCRIPTION FOR USER LOADED EVENT

		this.userInfoService.currentLoggedInUser.pipe(takeUntil(this.ngUnSubscribe$)).subscribe(async (user: InternalUserItem) => {
			if (user) {
				if (user.internalUserGUID != this.userInfoService.loggedInUserGUID) {
					this.userInfoService.loggedInUserGUID = user.internalUserGUID ?? '';
				}
				this.gsConnectService.postNotificationList.next([]);
				if (user.smallPictureS3FileGUID) {
					let fileToLoad = await this.fileService.downloadS3FilePreSignedUrl(
						{
							s3FileGUID: user.smallPictureS3FileGUID,
							createDownloadLink: false,
						},
						true
					);
					if (user.internalUserGUID == this.userInfoService.loggedInUserGUID) {
						if (fileToLoad?.file) {
							this.userProfileImage = URL.createObjectURL(fileToLoad?.file);
						} else {
							this.userProfileImage = this.defaultProfileImage;
						}
					} else {
						return;
					}
				} else {
					this.userProfileImage = this.defaultProfileImage;
				}
				this.loggedInUserName = user.name ?? '';
				this.navigationMenuList = this.prepareNavigationMenu();
				this.checkInitialMySuggestionsCount();
				this.getGsConnectNotification();
			}
		});

		// SUBSCRIPTION WHEN USER IS LOADED
		this.store
			.select(userIdState)
			.pipe(takeUntil(this.ngUnSubscribe$))
			.subscribe(async (userId) => {
				if (userId) {
					this.navigationMenuList = this.prepareNavigationMenu();
					// if (this.allUsers && this.allUsers.length > 0) {
					// 	this.loggedInUser = this.allUsers.find((x) => x.email === userId)?.name ?? '';
					// }
				}
			});

		// SUBSCRIBING TO USER PERMISSION LOADED
		this.store
			.select(userPermissionLoaded)
			.pipe(takeUntil(this.ngUnSubscribe$))
			.subscribe(async (_allPermission: InternalTitlePermissionListItem[] | undefined) => {
				if (_allPermission) {
					this.userHasImpersonatePermission = await this.permissionService.userHasPermissionByKey('AdminMenuGroup.AllowUserImpersonation', this.authService.userId);
					this.profileMenuList = this.authService.isActiveImpersonation ? this.prepareActiveImpersonationMenu() : this.prepareInactiveImpersonationMenu();
					this.hasFeedbackPermission = await this.permissionService.userHasPermission(this.addFeedbackPermissionRoute);
				}
			});
		this.store
			.select(suggestionUpdated)
			.pipe(takeUntil(this.ngUnSubscribe$))
			.subscribe((updated: boolean) => {
				if (updated) {
					this.checkMySuggestionsCount();
					this.store.dispatch(
						SuggestionUpdateAction({
							suggestionUpdated: false,
						})
					);
				}
			});
		this.gsConnectService.postNotificationList.pipe(takeUntil(this.ngUnSubscribe$)).subscribe((list: GsConnectNotificationItem[]) => {
			if (list.length > 0) {
				this.hasPostNotification = list.filter((t) => t.hasNewNotification).length > 0;
			} else {
				this.hasPostNotification = false;
			}
		});
	}

	toggleSideNav() {
		this.containerExpanded = !this.containerExpanded;
		this.togglerIcon = this.containerExpanded ? 'bx-chevron-left' : 'bx-chevron-right';
		this.onTogglerClick.emit();
	}

	/**
	 * Expand the toggler nav bar
	 */
	expandSideNav() {
		this.containerExpanded = true;
		this.togglerIcon = 'bx-chevron-left';
	}

	/**
	 * Click event on favorite menu item clicked. It shows tippy favorite popup
	 */
	showFavoritePopup() {
		this.isFavoritePopupInitialized = true;
	}
	disableParentTippyComponent(className: string) {
		const relativePos = document.getElementsByClassName(className)[0];
		relativePos?.classList?.add('pos-relative');
	}

	showMyDayPopup() {
		this.isMyDayInitialized = true;
	}
	showFeedbackPopup() {
		//this.isFeedbackInitialized = true;
		let feedbackComponentGUID = this.routePathResolver.resolveRouterUrlGUIDFromComponentName('ViewFeedbackComponent');
		this.router.navigate(['', feedbackComponentGUID]);
	}
	openGsConnectPage() {
		//if (this.gsConnectService.postNotificationList) {
		this.isGsConnectNotificationInitialized = true;
		//} else {
		//let gsConnectComponentGUID = this.routePathResolver.resolveRouterUrlGUIDFromComponentName('GsConnectComponent');
		//this.router.navigate(['', gsConnectComponentGUID]);
		//}
	}
	onProfileMenuClick(menuName: string) {
		switch (menuName) {
			case 'Profile':
				this.componentGUID = this.routePathResolver.resolveRouterUrlGUIDFromComponentName('ProfileComponent');
				this.router.navigate(['', this.componentGUID]);
				break;
			case 'Logout':
				this.onLogoutUser();
				break;
			case 'Impersonate':
				this.onImpersonate();
				break;
			case 'Stop-Impersonate':
				this.onStopUserImpersonation();
				break;
			default:
		}
	}

	onLogoutUser() {
		if (this.hasUnsavedChanges) {
			this.confirmationPopUpService.showConfirmationModal({
				title: '',
				message: '',
				onConfirm: () => {
					this.authService.logout();
				},
			});
		} else {
			this.authService.logout();
		}
	}

	onImpersonate() {
		if (this.hasUnsavedChanges) {
			this.confirmationPopUpService.showConfirmationModal({
				title: '',
				message: '',
				onConfirm: () => {
					this.openImpersonateDialog({});
				},
			});
		} else {
			this.openImpersonateDialog({});
		}
	}
	openImpersonateDialog(data: any) {
		const dialogRef = this.dialog.open(ImpersonateUserComponent, {
			width: '450px',
			data: data,
		});
		dialogRef.afterClosed().subscribe(async (result: any) => {
			if (result.data) {
				this.router.navigate(['']);
			}
		});
	}
	onStopUserImpersonation() {
		if (this.hasUnsavedChanges) {
			this.confirmationPopUpService.showConfirmationModal({
				title: '',
				message: '',
				onConfirm: () => {
					this.authService.stopImpersonation(this.authService.userId);
					this.router.navigate(['']);
				},
			});
		} else {
			this.authService.stopImpersonation(this.authService.userId);
			this.router.navigate(['']);
		}
	}

	prepareActiveImpersonationMenu(): DropDownMenuModel[] {
		let menuModels: DropDownMenuModel[] = [];
		menuModels = _.cloneDeep(this.baseMenuModels);
		menuModels.push({
			name: 'Stop-Impersonate',
			icon: 'bx-user',
			onClick: this.onProfileMenuClick.bind(this),
		});
		return menuModels;
	}

	prepareInactiveImpersonationMenu(): DropDownMenuModel[] {
		let menuModels: DropDownMenuModel[] = [];
		menuModels = _.cloneDeep(this.baseMenuModels);
		if (this.userHasImpersonatePermission) {
			const hasItem = menuModels.find((x) => x.name === 'Impersonate');
			if (!hasItem) {
				menuModels.push({
					name: 'Impersonate',
					icon: 'bx-user',
					onClick: this.onProfileMenuClick.bind(this),
				});
			}
		}
		return menuModels;
	}

	prepareNavigationMenu(): DropDownMenuModel[] {
		const menuModel: DropDownMenuModel[] = [
			{
				name: 'Settings',
				icon: 'bx-user',
				onClick: this.onProfileMenuClick.bind(this),
			},
			{
				name: 'Favorites',
				icon: 'bx-left-arrow-circle',
				onClick: this.onProfileMenuClick.bind(this),
			},
			{
				name: 'Todo List',
				icon: 'bx-user',
				onClick: this.onProfileMenuClick.bind(this),
			},
			{
				name: 'Notifications',
				icon: 'bx-user',
				onClick: this.onProfileMenuClick.bind(this),
			},
		];
		return menuModel;
	}
	ngOnDestroy(): void {
		this.ngUnSubscribe$.next();
		this.ngUnSubscribe$.unsubscribe();
	}
	async getGsConnectNotification() {
		let loggedInUser = this.userInfoService.currentLoggedInUser.value;
		const response = await this.gsConnectService.fetchPostNotifications(loggedInUser.internalUserGUID ?? '');
		if (response.isSuccess && response.data && response.data?.length > 0) {
			this.hasPostNotification = response.data.filter((t) => t.hasNewNotification).length > 0;
		} else {
			this.hasPostNotification = false;
		}
	}
	async checkInitialMySuggestionsCount() {
		let loggedInUser = this.userInfoService.currentLoggedInUser.value;
		const response = await this.feedbackService.fetchAllSuggestion(loggedInUser);
		this.hasSuggestions = response.isSuccess && response.data == true;
	}
	async checkMySuggestionsCount() {
		this.hasSuggestions = this.feedbackService.showArchivedSuggestionCount || this.feedbackService.showMySuggestionCount || this.feedbackService.showSuggestionReceivedCount;
	}
}
