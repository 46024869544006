import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { RoutePathResolverService } from '../../services/route-path-resolver.service';
import { ComponentResolverService } from '../../services/component-resolver.service';
import { RoutePathConfig } from '../../models/models';
import { Subscription, filter, map, timer } from 'rxjs';
import { ComponentHeightInPixel } from '../../../models/classes';
import { UserInfoService } from '../../../services/user-info.service';
import { NotificationService } from '../../components/stand-alone/notifications/services/notification.service';
import { NotificationMessage, NotificationMessageType, NotificationReceiveInfoModel, NotificationType } from '../../../models/models';
import { SendUserMessage } from '../../components/stand-alone/notifications/services/notificationModel';
import { Store } from '@ngrx/store';
import { AppState } from '../../../models/classes';
import { notificationAddedState } from '../../../store/store.selector';
import { ErrorHandlingService } from '../../../../../../goldstar-share/src/app/services/error-handling.service';
import { NotificationUpdateModel, UserGroupNotificationMessage } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { WikiManualService } from '../stand-alone/resource-center/services/wiki-manual.service';
import { menuLoadedState } from '../../../store/store.selector';
import { MenuService } from '../../../services/menuService';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
	selector: 'app-content-container',
	templateUrl: './content-container.component.html',
	styleUrls: ['./content-container.component.scss'],
})
export class ContentContainerComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	public styleCollection: Record<string, string | undefined | null> = {
		height: '76%',
	};

	public selectedRouteConfig!: RoutePathConfig;

	public headerWithImage!: boolean;
	public routedRoute!: boolean;
	public showBreadcrumb!: boolean;
	@Input() sideBarExpanded!: boolean;
	public routedRouteComponent: string = '';
	public wikiComponentName: string = 'WikiManualComponent';

	public notificationConnectSubscription$!: Subscription;
	public notificationAddedSubscription$!: Subscription;
	public timerSubscription$!: Subscription;
	public menuLoaded$!: Subscription;

	public readonly layoutComponentFixedHeightInPixel =
		ComponentHeightInPixel.breadcrumbHeight + ComponentHeightInPixel.footerHeight + ComponentHeightInPixel.topNavbarHeight + (ComponentHeightInPixel.paddingHeight - 10);
	public readonly layoutWithoutBreadcrumbInPixel = ComponentHeightInPixel.footerHeight + ComponentHeightInPixel.topNavbarHeight + (ComponentHeightInPixel.paddingHeight + 100);
	public readonly pageNotFoundLayoutHeightInPixel = ComponentHeightInPixel.footerHeight + ComponentHeightInPixel.topNavbarHeight - 40;

	@Output() onTogglerClick: EventEmitter<void> = new EventEmitter();

	@ViewChild('dynamicComponent', { read: ViewContainerRef }) dynamicComponent!: ViewContainerRef;

	public contentContainerSpinner: string = 'contentContainerSpinner';

	// Windows update event on form
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.calculateScreenHeight();
	}
	constructor(
		public activatedRoute: ActivatedRoute,
		public router: Router,
		private store: Store<AppState>,
		private routeResolverService: RoutePathResolverService,
		private componentResolver: ComponentResolverService,
		private userInfoService: UserInfoService,
		private notificationService: NotificationService,
		private errorHandlingService: ErrorHandlingService,
		private wikiManualService: WikiManualService,
		private menuService: MenuService,
		private spinnerService: NgxSpinnerService
	) {
		this.handleSubscription = this.handleSubscription.bind(this);
	}
	ngAfterViewInit(): void {
		this.router.events
			.pipe(filter((event: any) => event instanceof NavigationEnd))
			.pipe(map(() => this.activatedRoute.snapshot))
			.subscribe(() => {
				// ONLY HOME ROUTE SINCE IT NOT PART OF THE DEFAULT ROUTE IS HERE. NEED TO LOOK TO WAYS TO REMOVE THIS.
				if (this.router.url === '/') {
					this.routeResolverService.resolveHomeRoutePath();
				}
			});

		this.routeResolverService.getRoutedPath().subscribe((value: RoutePathConfig) => {
			this.routedRoute = value.rooted;
			this.selectedRouteConfig = value;
			// For Home and PageNotFound component the breadcrumb component should not be visible.
			this.showBreadcrumb = false;
			this.calculateScreenHeight();
			const existingBredCrumbList = [...this.menuService.breadcrumb];

			//check if overdraft reports ,then close the toggle the main sidebar
			const hasOverdraft = existingBredCrumbList.some((obj) => obj.label.toLowerCase().includes('overdraft'));
			const hasReporting = existingBredCrumbList.some((obj) => obj.label.toLowerCase().includes('report'));
			const hasGsConnect = existingBredCrumbList.some((obj) => obj.label.toLowerCase().includes('connect'));
			// Handled sidebar collapsing when navigating to Resource Center
			if (value.component === this.wikiComponentName) {
				if (this.routedRouteComponent !== this.wikiComponentName) {
					// Reset expanded wiki sidebar list when navigating to the resource center
					this.wikiManualService.sidebarTreeList = [];
					this.routedRouteComponent = value.component ?? '';
					if (this.sideBarExpanded) {
						this.toggleSideNav();
					}
				}
			} else if (((hasOverdraft && hasReporting) || hasGsConnect) && this.sideBarExpanded) {
				this.toggleSideNav();
			} else {
				if (this.routedRouteComponent === this.wikiComponentName && !this.sideBarExpanded) {
					this.toggleSideNav();
				}
				this.routedRouteComponent = '';
			}

			if (!this.routedRoute) {
				this.renderComponent(value.component);
			}
		});

		// this.notificationConnectSubscription$ = this.notificationService.connect().subscribe(async (response: any) => await this.subscribeToMessages(response));

		// Subscribing to new notification messages
		this.notificationAddedSubscription$ = this.store.select(notificationAddedState).subscribe((notification: NotificationReceiveInfoModel | undefined) => {
			if (notification) {
				if (notification?.silentYN === 'N') {
					this.processNotificationToaster(notification);
				} else {
					this.notificationService.markNotificationAsDelivered(notification);
				}
			}
		});

		this.menuLoaded$ = this.store.select(menuLoadedState).subscribe(async (menuLoaded) => {
			if (menuLoaded) {
				this.connectUser();
				this.subscribeToWebSocketConnection();
			}
		});
	}

	ngOnInit(): void {
		window.addEventListener('beforeunload', this.onBrowserClosed.bind(this));
	}

	async onBrowserClosed() {
		this.disconnectUser();
	}

	showHideSpinner(showSpinner: boolean) {
		if (showSpinner) {
			this.spinnerService.show(this.contentContainerSpinner);
		} else {
			this.spinnerService.hide(this.contentContainerSpinner);
		}
	}
	ngOnChanges(changes: SimpleChanges): void {
		if (this.sideBarExpanded) {
			this.sideBarExpanded = true;
		}
	}

	toggleSideNav() {
		this.onTogglerClick.emit();
	}

	async renderComponent(component?: string) {
		if (!component) {
			// THE DEFAULT PAGE TO BE LOADED FOR ANY FAILURE WHILE RESOLVING COMPONENT
			console.log('MISSING COMPONENT');
			component = 'PageNotFoundComponent';
			this.headerWithImage = false;
		}
		this.componentResolver.resolveComponent(component).subscribe((component: any) => {
			this.dynamicComponent?.clear();
			this.dynamicComponent?.createComponent(component);
			const componentName = component?.prototype?.resolveComponentName();
			this.showBreadcrumb = !componentName || componentName === 'HomeComponent' || componentName === 'PageNotFoundComponent' ? false : true;
			this.headerWithImage = componentName === 'PageNotFoundComponent' ? false : !this.showBreadcrumb;
		});
	}

	async calculateScreenHeight() {
		const screenHeight = window.innerHeight;
		if (this.showBreadcrumb) {
			const contentContainerHeightInPixel = screenHeight - this.layoutComponentFixedHeightInPixel;
			this.styleCollection = {
				height: `${contentContainerHeightInPixel}px !important`,
			};
		} else {
			if (this.selectedRouteConfig.component === 'HomeComponent') {
				const contentContainerHeightInPixel = screenHeight - this.layoutWithoutBreadcrumbInPixel;
				this.styleCollection = {
					height: `${contentContainerHeightInPixel}px !important`,
				};
			}
			if (!this.selectedRouteConfig.component) {
				const contentContainerHeightInPixel = screenHeight - this.pageNotFoundLayoutHeightInPixel;
				this.styleCollection = {
					height: `${contentContainerHeightInPixel}px !important`,
					'margin-top': '-15px !important',
				};
			}
		}
	}

	async subscribeToMessages(response: any) {
		const message: SendUserMessage = response;
		if (!message) return;
		switch (message.type) {
			case NotificationMessageType.Notification:
				await this.notificationService.addNotificationMessage(message);
				break;
			case NotificationMessageType.Chat:
				break;
			default:
				console.log('Type not supported');
				break;
		}
	}

	processNotificationToaster(messageInfoModel: NotificationReceiveInfoModel) {
		if (!messageInfoModel) return;
		switch (messageInfoModel.level) {
			case 'Medium':
				const mediumMessage = this.parseNotificationMessage(messageInfoModel);
				this.errorHandlingService.showMediumLevelNotification(messageInfoModel, mediumMessage, this, this.onNotificationMessageClick);
				this.notificationService.markNotificationAsDelivered(messageInfoModel);
				break;
			case 'High':
				const highMessage = this.parseNotificationMessage(messageInfoModel);
				this.errorHandlingService.showHighLevelNotification(messageInfoModel, highMessage, this, this.onNotificationMessageClick);
				this.notificationService.markNotificationAsDelivered(messageInfoModel);
				break;
			case 'Low':
				const lowMessage = this.parseNotificationMessage(messageInfoModel);
				this.errorHandlingService.showInfoLevelNotification(messageInfoModel, lowMessage, this, this.onNotificationMessageClick);
				this.notificationService.markNotificationAsDelivered(messageInfoModel);
				break;
		}
	}

	parseNotificationMessage(messageInfoModel: NotificationReceiveInfoModel): string {
		try {
			switch (messageInfoModel.type) {
				case NotificationMessageType.Chat:
					return '';
				case NotificationMessageType.Notification:
					switch (messageInfoModel.mode) {
						case NotificationType.Group:
						case NotificationType.Single:
							const message = JSON.parse(messageInfoModel.messageBody);
							if (!message || message == null) throw Error('Could not parse empty message details');
							return message;
						default:
							return '';
					}
				default:
					return '';
			}
		} catch (error: any) {
			this.errorHandlingService.showErrorMessage(error.message);
			throw error;
		}
	}

	async onNotificationMessageClick(model: NotificationReceiveInfoModel, instance: this) {
		try {
			switch (model.type) {
				case NotificationMessageType.Chat:
					throw Error('Not supported exception');
				case NotificationMessageType.Notification:
					switch (model.mode) {
						case NotificationType.Group:
							await instance.markNotificationRead(model);
							if (
								model.redirectComponent &&
								model.redirectComponent != null &&
								model.entityKeyProp &&
								model.entityKeyProp != null &&
								model.entityKeyValue &&
								model.entityKeyValue != null
							) {
								const componentGUID = instance.routeResolverService.resolveRouterUrlGUIDFromComponentName(model.redirectComponent);
								let params: any = {};
								params[`${model.entityKeyProp}`] = model.entityKeyValue;
								instance.router.navigate(['', componentGUID], { queryParams: params });
							}
							break;
						case NotificationType.Single:
							if (
								model.redirectComponent &&
								model.redirectComponent != null &&
								model.entityKeyProp &&
								model.entityKeyProp != null &&
								model.entityKeyValue &&
								model.entityKeyValue != null
							) {
								const componentGUID = instance.routeResolverService.resolveRouterUrlGUIDFromComponentName(model.redirectComponent);
								let params: any = {};
								params[`${model.entityKeyProp}`] = model.entityKeyValue;
								instance.router.navigate(['', componentGUID], { queryParams: params });
							}
							break;
					}
			}
		} catch (error: any) {
			instance.errorHandlingService.showInfoMessage(error.message);
		}
	}

	async markNotificationRead(model: NotificationReceiveInfoModel) {
		try {
			const loggedInUser = await this.userInfoService.getCurrentLoggedInUser();
			if (model) {
				const updateNotificationRequest: NotificationUpdateModel = {
					notificationGUID: model.notificationGUID,
					readYN: true,
					toUserGUID: loggedInUser.internalUserGUID,
					type: model.type,
				};
				await this.notificationService.markNotificationAsRead(updateNotificationRequest);
			}
		} catch (error: any) {
			throw error;
		}
	}

	async subscribeToWebSocketConnection() {
		let maximumNumberOfReconnects = 900000;
		this.timerSubscription$ = timer(0, 2000)
			.pipe(
				map(() => {
					if (maximumNumberOfReconnects >= 0) {
						this.pollUserNotifications();
						maximumNumberOfReconnects = maximumNumberOfReconnects - 1;
					}
				})
			)
			.subscribe();
	}

	async connectUser() {
		const loggedInUser = await this.userInfoService.getCurrentLoggedInUser();
		let data = {
			action: 'connectUser',
			message: {
				internalUserGUID: loggedInUser.internalUserGUID,
			},
		};
		this.notificationService.sendMessage(data);
	}

	async pollUserNotifications() {
		const loggedInUser = await this.userInfoService.getCurrentLoggedInUser();
		let data = {
			action: 'fetchUnreadNotifications',
			message: {
				internalUserGUID: loggedInUser.internalUserGUID,
			},
		};
		this.notificationService.sendMessage(data).subscribe(this.handleSubscription, this.handleError);
	}

	async handleSubscription(response: unknown) {
		await this.subscribeToMessages(response);
	}

	handleError(error: Error) {
		console.log('Error');
	}

	async disconnectUser() {
		const loggedInUser = await this.userInfoService.getCurrentLoggedInUser();
		let data = {
			action: 'disconnectUser',
			message: {
				internalUserGUID: loggedInUser.internalUserGUID,
			},
		};
		this.notificationService.sendMessage(data);
	}

	ngOnDestroy(): void {
		this.notificationAddedSubscription$?.unsubscribe();
		this.notificationConnectSubscription$?.unsubscribe();
		this.timerSubscription$.unsubscribe();
	}
}
